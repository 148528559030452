import React from "react";
// material

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// layouts
// import AuthLayout from '../../layouts/AuthLayout';
// components
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardRoutes } from "..";
import images from "../assets/images";
import Page from "../components/Page";
import useTimer from "../hooks/useTimer";
// ----------------------------------------------------------------------

const TIMEOUT = 5;

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.primary.main,
  height: "100%",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

const CardContentStyle = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  padding: theme.spacing(4),
}));
// ----------------------------------------------------------------------

const PaymentCanceled: React.FC = () => {
  const { t } = useTranslation("paymentCanceledPage");

  const navigate = useNavigate();

  const timeLeft = useTimer(TIMEOUT, () => {
    navigate(DashboardRoutes.YOUR_SUBSCRIPTION, { replace: true });
  });

  return (
    <RootStyle title={t("title")}>
      <ContentStyle>
        <Slide in={true}>
          <Card>
            <CardContentStyle>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box component="img" src={images.CancelIcon} width={48} />
                <Box height={16} />
                <Typography variant="h3" color="error" textAlign="center">
                  {t("title")}
                </Typography>
                <Box height={32} />
                <Typography textAlign="center">
                  {t("redirect", { seconds: timeLeft })}
                </Typography>
                <Box height={16} />
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              </Stack>
            </CardContentStyle>
          </Card>
        </Slide>
      </ContentStyle>
    </RootStyle>
  );
};

export default PaymentCanceled;
