import React from "react";
// material
import {
  Box,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// components
import Flag from "react-flagkit";
import { useTranslation } from "react-i18next";
import MenuPopover from "../components/MenuPopover";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "en-EN",
    country: "US",
    label: "English",
    icon: <Flag country="US" />,
  },
  {
    value: "it-IT",
    country: "IT",
    label: "Italiano",
    icon: <Flag country="IT" />,
  },
];

// ----------------------------------------------------------------------

const LanguagePopover: React.FC = () => {
  const { i18n } = useTranslation();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (lng: string) => {
    i18n.changeLanguage(lng);
    setOpen(false);
  };

  const currentLanguage = React.useMemo(() => {
    return i18n.language.toUpperCase().split("-")[0];
  }, [i18n.language]);

  const currentCountryFlag = React.useMemo(() => {
    switch (currentLanguage) {
      case "EN":
        return <Flag country="US" />;

      default:
        return <Flag country={currentLanguage} />;
    }
  }, [currentLanguage]);

  return (
    <Stack spacing={0.25} direction="row" alignItems="center">
      <Typography>{currentLanguage}</Typography>

      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.focusOpacity
                ),
            }),
          }}
        >
          {currentCountryFlag}
        </IconButton>
      </ClickAwayListener>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={i18n.language === option.value}
              onClick={() => handleClose(option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </Stack>
  );
};

export default LanguagePopover;
