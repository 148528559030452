import { forwardRef } from "react";
// material
import { Box, BoxProps } from "@mui/material";
import { enUS as enLocale, it as itLocale } from "date-fns/locale";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import PageTitle from "./PageTitle";
// ----------------------------------------------------------------------

interface IPageProps extends BoxProps {
  showBackButton?: boolean;
  hidePageTitle?: boolean;
}

const localeMap = {
  us: enLocale,
  it: itLocale,
};

const Page = forwardRef<HTMLDivElement, IPageProps>(
  (
    {
      children,
      title = "",
      showBackButton = false,
      hidePageTitle = true,
      ...other
    },
    ref
  ) => {
    const { i18n } = useTranslation();

    return (
      <Box
        ref={ref}
        sx={(theme) => ({
          marginTop: `${theme.appBar.md}px`,
          height: `calc(100vh - ${theme.appBar.md}px)`,
        })}
        {...other}
      >
        <Helmet>
          <title>{title} | Identikyc</title>
        </Helmet>

        <PageTitle
          showBackButton={showBackButton}
          hidePageTitle={hidePageTitle}
        >
          {title}
        </PageTitle>

        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={
            // @ts-ignore
            localeMap[i18n.language]
          }
        >
          {children}
        </LocalizationProvider>
      </Box>
    );
  }
);

export default Page;
