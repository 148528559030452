import Parse from "parse";
import React from "react";
// material

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";

import DownloadIcon from "@mui/icons-material/Download";
import PersonIcon from "@mui/icons-material/PersonOutline";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import RefreshIcon from "@mui/icons-material/Refresh";
import { format, fromUnixTime, sub } from "date-fns";
import { useAsync } from "react-async-hook";
import type { IInvoiceData } from "../hooks/useInvoices";
import { useInvoices } from "../hooks/useInvoices";
import { useUserActiveSubscription } from "../hooks/useUserActiveSubscription";

const YourSubscriptionPage: React.FC = () => {
  const { t } = useTranslation("yourSubscriptionPage");
  const theme = useTheme();

  const {
    loading: isLoadingInvoices,
    result: invoices,
    refresh: refreshInvoices,
  } = useInvoices();

  const { loading: isLoadingSubscription, result: subscription } =
    useUserActiveSubscription();

  const { result: identitiesLeft, loading: isLoadingIdentitiesLeft } = useAsync<
    number | undefined
  >(async () => {
    const user = await Parse.User.current()?.fetch();
    if (user === undefined) {
      return 0;
    }

    return (
      (user.get("subscriptionIdentitiesLeft") ?? 0) +
      (user.get("consumableIdentitiesLeft") ?? 0)
    );
  }, []);

  return (
    <Page title={t("title")} hidePageTitle={false} showBackButton={false}>
      <Stack direction="column" sx={{ mx: 4 }} spacing={2} py={4}>
        {isLoadingSubscription && <CircularProgress />}
        {!isLoadingSubscription && !subscription && (
          <Card variant="outlined">
            <CardContent style={{ height: "100%", padding: 0 }}>
              <Stack direction="column">
                <Stack
                  direction={{ sm: "row", xs: "column" }}
                  height="100%"
                  padding={4}
                  justifyContent="space-between"
                  alignItems={{ sm: "initial", xs: "center" }}
                >
                  <Stack
                    direction="column"
                    flex={0.5}
                    alignItems={{ sm: "initial", xs: "center" }}
                  >
                    <Typography variant="overline">{t("plan")}</Typography>
                    <Typography variant="h3" fontWeight="bold">
                      N/A
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    flex={0.5}
                    alignItems={{ sm: "initial", xs: "center" }}
                  >
                    <Typography variant="overline">{t("payment")}</Typography>
                    <Typography variant="h3" fontWeight="bold">
                      $0{" "}
                      <Typography variant="subtitle1" display="inline">
                        {t("perMonth")}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    flex={0.5}
                    alignItems={{ sm: "initial", xs: "center" }}
                  >
                    <Typography variant="overline">
                      {t("identities")}
                    </Typography>
                    <Typography variant="h3" fontWeight="bold">
                      0{" "}
                      <Typography variant="subtitle1" display="inline">
                        {t("perMonth")}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
                <Divider color={theme.palette.grey[200]} />
                <Stack
                  direction={{ sm: "row", xs: "column" }}
                  justifyContent="space-between"
                  height="100%"
                  padding={4}
                  spacing={4}
                >
                  {!isLoadingIdentitiesLeft && identitiesLeft !== undefined && (
                    <>
                      <UsageItem
                        title={t("usageItems.identitiesLeft")}
                        value={identitiesLeft}
                        max={100}
                        icon={<PersonIcon />}
                      />
                    </>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
        {subscription && (
          <Card variant="outlined">
            <CardContent style={{ height: "100%", padding: 0 }}>
              <Stack direction="column">
                <Stack
                  direction={{ sm: "row", xs: "column" }}
                  height="100%"
                  padding={4}
                  justifyContent="space-between"
                  alignItems={{ sm: "initial", xs: "center" }}
                >
                  <Stack
                    direction="column"
                    flex={0.5}
                    alignItems={{ sm: "initial", xs: "center" }}
                  >
                    <Typography variant="overline">{t("plan")}</Typography>
                    <Typography variant="h3" fontWeight="bold">
                      {subscription.name.toUpperCase()}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    flex={0.5}
                    alignItems={{ sm: "initial", xs: "center" }}
                  >
                    <Typography variant="overline">{t("payment")}</Typography>
                    <Typography variant="h3" fontWeight="bold">
                      ${subscription.price.value}{" "}
                      <Typography variant="subtitle1" display="inline">
                        {t("perMonth")}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    flex={0.5}
                    alignItems={{ sm: "initial", xs: "center" }}
                  >
                    <Typography variant="overline">
                      {t("identities")}
                    </Typography>
                    <Typography variant="h3" fontWeight="bold">
                      {subscription.metadata.identities}{" "}
                      <Typography variant="subtitle1" display="inline">
                        {t("perMonth")}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
                <Divider color={theme.palette.grey[200]} />
                <Stack
                  direction={{ sm: "row", xs: "column" }}
                  justifyContent="space-between"
                  height="100%"
                  padding={4}
                  spacing={4}
                >
                  {!isLoadingIdentitiesLeft && identitiesLeft !== undefined && (
                    <>
                      <UsageItem
                        title={t("usageItems.identitiesLeft")}
                        value={identitiesLeft}
                        max={100}
                        icon={<PersonIcon />}
                      />
                    </>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}

        <Typography textTransform="uppercase">
          {t("invoices", {
            invoices: invoices.length,
          })}
          <IconButton onClick={refreshInvoices}>
            <RefreshIcon />
          </IconButton>
        </Typography>
        {isLoadingInvoices && <CircularProgress />}
        {invoices.map((invoice, i) => {
          return <InvoiceListItem invoice={invoice} />;
        })}
      </Stack>
    </Page>
  );
};

type TInvoiceListItemProps = {
  invoice: IInvoiceData;
};

const InvoiceListItem: React.FC<TInvoiceListItemProps> = ({ invoice }) => {
  const { t } = useTranslation("yourSubscriptionPage");
  const startDate = format(fromUnixTime(invoice.periodStart), "dd/MM/yyyy");
  //const endDate = format(fromUnixTime(invoice.periodEnd), "dd/MM/yyyy");
  const title = t("invoiceListItem.title");

  return (
    <Card variant="outlined">
      <Stack
        direction={{ sm: "row", xs: "column" }}
        p={3}
        alignItems="center"
        justifyContent={{ sm: "space-between", xs: "center" }}
        spacing={2}
      >
        <PDFIcon fontSize="large" color="action" />
        <Typography flex={1}>{title}</Typography>
        <Typography component="div" flex={1} color="GrayText">
          {t("invoiceListItem.date")}{" "}
          <Typography display="inline" color="black">
            {startDate}
          </Typography>
        </Typography>
        <Stack direction={{ xs: "row" }} spacing={{ xs: 2 }}>
          {/*invoice.get("data").hosted_invoice_url */}
          <a href={invoice.hostedInvoiceUrl} target="_blank" rel="noreferrer">
            <EyeIcon color="primary" />
          </a>
          {/*invoice.get("data").invoice_url */}
          <a href={invoice.invoicePdf} target="_blank" rel="noreferrer">
            <DownloadIcon color="primary" />
          </a>
        </Stack>
      </Stack>
    </Card>
  );
};

type TUsageItem = {
  title: string;
  value: number;
  max: number;
  icon: React.ReactNode;
};
const UsageItem: React.FC<TUsageItem> = ({ title, value, max, icon }) => {
  //const percentage = (value / max) * 100;

  //const barcolor =
  //percentage > 80 ? "red" : percentage > 50 ? "orange" : undefined;
  const textColor = value === 0 ? "red" : undefined;
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      //flex={1}
      spacing={2}
    >
      <Typography variant="body2" fontWeight="bold">
        {title}
      </Typography>
      {/*<BorderLinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: barcolor,
          },
        }}
      />*/}
      <Stack direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          spacing={1}
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <Box color={textColor}>{icon}</Box>
          <Typography color={textColor}>
            {value} {/*({percentage.toFixed(1)}%)*/}
          </Typography>
        </Stack>
        {/*<Typography>{max}</Typography>*/}
      </Stack>
    </Stack>
  );
};

/*const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));*/

export default YourSubscriptionPage;
