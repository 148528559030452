// material
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
//
import { itIT } from "@mui/x-data-grid";
import React from "react";
import appBar from "./appBar";
import palette from "./palette";
import shadows from "./shadows";

// ----------------------------------------------------------------------

const ThemeConfig: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const themeOptions = React.useMemo(
    () => ({
      appBar,
      palette,
      shadows,
    }),
    []
  );

  const theme = createTheme(
    {
      shape: {
        borderRadius: 2.7,
      },
      typography: {
        fontFamily: "'Poppins', sans-serif",
        h1: { fontSize: "2.25rem" },
        h2: { fontSize: "2.01rem" },
        h3: { fontSize: "1.7rem" },
        h4: { fontSize: "1.5rem" },
        h5: { fontSize: "1.3rem" },
        h6: { fontSize: "1rem" },
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: ({ theme }) => ({
              backgroundColor: `${theme.palette.primary.main} !important`,
            }),
          },
        },
      },
      ...themeOptions,
    },
    itIT
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
