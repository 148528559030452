import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Slide,
  useTheme,
} from "@mui/material";

import { TransitionProps } from "@mui/material/transitions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardRoutes } from "../..";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UnlockIdentityFailedDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(["unlockIdentityFailedDialog"]);
  const theme = useTheme();
  const navigate = useNavigate();

  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = React.useCallback(() => {
    // @ts-ignore
    onClose?.();
  }, [onClose]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Limite d'identità raggiunto"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Pare tu abbia raggiunto il numero d'identità generabili. Rinnova la
          tua sottoscrizione o acquista altre identità.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Chiudi</Button>
        <Button onClick={() => navigate(DashboardRoutes.BUY)}>
          Vai all'acquisto
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnlockIdentityFailedDialog;
