import Parse from "parse";
import React from "react";
// material
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteForever from "@mui/icons-material/DeleteForever";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { LoadingButton } from "@mui/lab";
import { Chip, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridFilterItem, itIT } from "@mui/x-data-grid";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardRoutes } from "..";
import { Footer, NoRowsOverlay } from "../components/Datagrid";
import Page from "../components/Page";
import useDownloadPdf from "../hooks/useDownloadPdf";
import useDeleteIdentity from "../hooks/useDeleteIdentity";
import { useUnlockIdentity } from "../hooks/useUnlockIdentity";
import UnlockIdentityFailedDialog from "../components/Dialogs/UnlockIdentityFailedDialog";

const DownloadIdenityPdf: React.FC<{ identityDetails: Parse.Object }> = ({
  identityDetails,
}) => {
  const { isDownloadingPdf, downloadPdf } = useDownloadPdf({ identityDetails });

  return (
    <IconButton
      component={LoadingButton}
      loading={isDownloadingPdf}
      onClick={downloadPdf}
    >
      {!isDownloadingPdf && <PictureAsPdfIcon color="primary" />}
    </IconButton>
  );
};

const DeleteIdentity: React.FC<{
  identityDetails: Parse.Object;
  onDelete: () => void;
}> = ({ identityDetails, onDelete }) => {
  const { isDeletingIdentity, deleteIdentity } = useDeleteIdentity({
    identityDetails,
    onDelete: onDelete,
  });

  return (
    <IconButton
      component={LoadingButton}
      loading={isDeletingIdentity}
      onClick={deleteIdentity}
    >
      {!isDeletingIdentity && <DeleteForever color="error" />}
    </IconButton>
  );
};

const UnlockIdentity: React.FC<{
  identityDetails: Parse.Object;
  onUnlock: () => void;
  onError: () => void;
}> = ({ identityDetails, onUnlock, onError }) => {
  const { isUnlocking, execute } = useUnlockIdentity({
    identityId: identityDetails.id,
    onUnlock: onUnlock,
    onError: onError,
  });
  return (
    <IconButton
      component={LoadingButton}
      loading={isUnlocking}
      onClick={execute}
    >
      {!isUnlocking && <LockOpenIcon color="error" />}
    </IconButton>
  );
};

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);

  const [filters, setFilters] = React.useState<GridFilterItem[]>();
  const [
    isUnlockIdentityFailedDialogShown,
    setIsUnlockIdentityFailedDialogShown,
  ] = React.useState(false);

  const columns: GridColDef[] = [
    {
      field: "Actions",
      headerName: "",
      align: "center",
      disableColumnMenu: true,
      disableExport: true,
      width: 128, // 196
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const isLocked = row.get("isLocked");
        return (
          <Stack direction="row">
            {isLocked ? (
              <UnlockIdentity
                identityDetails={row}
                onUnlock={refreshIdentities}
                onError={() => setIsUnlockIdentityFailedDialogShown(true)}
              />
            ) : (
              <DownloadIdenityPdf identityDetails={row} />
            )}
            <DeleteIdentity
              identityDetails={row}
              onDelete={refreshIdentities}
            />
          </Stack>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("authDate"),
      type: "date",
      width: 180,
      sortable: true,
      renderCell: ({ row }) => {
        const isLocked = row.get("isLocked");
        const parsedDate = new Intl.DateTimeFormat("it").format(
          new Date(row.createdAt)
        );

        return (
          <Chip
            label={isLocked ? t("unlockToShow") : parsedDate}
            variant="outlined"
            sx={{
              maxWidth: 180,
            }}
          />
        );
      },
      valueGetter: (parameters: any) => {
        if (!Number.isNaN(Date.parse(parameters.value))) {
          return new Intl.DateTimeFormat("it").format(
            new Date(parameters.value)
          );
        }

        return "";
      },
      sortComparator: (v1, v2) =>
        new Date(v1 as string).getTime() - new Date(v2 as string).getTime(),
    },
    {
      field: "firstName",
      headerName: t("firstName"),
      sortable: true,
      filterable: true,
      width: 130,
      valueGetter: (params) => params.row.get(params.field),
    },
    {
      field: "lastName",
      headerName: t("lastName"),
      sortable: true,
      filterable: true,
      width: 130,
      valueGetter: (params) => params.row.get(params.field),
    },
    {
      field: "gender",
      headerName: t("gender"),
      sortable: true,
      filterable: true,
      width: 150,
      valueGetter: (params) => t(params.row.get(params.field)),
    },
    {
      field: "dateOfBirth",
      headerName: t("birthDate"),
      type: "date",
      width: 200,
      sortable: true,
      renderCell: ({ row }) => {
        const isLocked = row.get("isLocked");
        const parsedDate = new Intl.DateTimeFormat("it").format(
          new Date(row.get("dateOfBirth"))
        );

        return (
          <Chip
            label={isLocked ? t("unlockToShow") : parsedDate}
            variant="outlined"
            sx={{
              maxWidth: 100,
            }}
          />
        );
      },
      valueGetter: (parameters: any) => {
        if (!Number.isNaN(Date.parse(parameters.value))) {
          return new Intl.DateTimeFormat("it").format(
            new Date(parameters.value)
          );
        }

        return "";
      },
      sortComparator: (v1, v2) =>
        new Date(v1 as string).getTime() - new Date(v2 as string).getTime(),
    },
    {
      field: "nationality",
      headerName: t("nationality"),
      sortable: true,
      filterable: true,
      width: 165,
      valueGetter: (params) => params.row.get(params.field),
    },
    {
      field: "documentNumber",
      headerName: t("documentNumber"),
      sortable: true,
      filterable: true,
      width: 225,
      renderCell: ({ row, value }) => (
        <Chip
          label={row.get("isLocked") ? t("unlockToShow") : value}
          color="secondary"
          variant="outlined"
          sx={{
            maxWidth: 225,
          }}
        />
      ),
      valueGetter: (params) => params.row.get(params.field),
    },
    {
      field: "type",
      headerName: t("documentType"),
      sortable: true,
      filterable: true,
      width: 200,
      valueGetter: (params) => params.row.get(params.field),
      renderCell: ({ value }) => (
        <Chip
          label={value === "P" ? t("passport") : t("identityCard")}
          color="secondary"
          variant="outlined"
          sx={{
            maxWidth: 225,
          }}
        />
      ),
    },
  ];

  const {
    loading: isExportingIdentities,
    result: exportedIdentities,
    execute: exportIdentities,
  } = useAsyncCallback(
    async () =>
      await Parse.Cloud.run("generatePdfWithFilters", {
        filters,
      })
  );

  const {
    loading: isLoadingIdentities,
    result: identities,
    execute: refreshIdentities,
  } = useAsync(async () => {
    const query = new Parse.Query<Parse.Object>("Identity")
      .equalTo("owner", Parse.User.current())
      .limit(pageSize)
      .skip(page * pageSize)
      .addDescending("createdAt")
      .withCount();

    if (filters && filters.length > 0) {
      filters.forEach((filter) => {
        switch (filter.operatorValue) {
          case "equals":
            query.equalTo(filter.columnField, filter.value);
            break;

          case "contains":
            query.contains(filter.columnField, filter.value);
            break;

          case "startsWith":
            query.startsWith(filter.columnField, filter.value);
            break;

          case "endsWith":
            query.endsWith(filter.columnField, filter.value);
            break;

          case "isEmpty":
            query.equalTo(filter.columnField, "");
            break;

          case "isNotEmpty":
            query.notEqualTo(filter.columnField, "");
            break;

          default:
            break;
        }
      });
    }

    return await query.find();
  }, [page, pageSize, filters]);

  React.useEffect(() => {
    if (exportedIdentities) {
      const buffer = Buffer.from(exportedIdentities, "base64");

      const blob = new Blob([buffer], {
        type: "application/zip; encoding=UTF-8",
      });

      saveAs(blob, "identities");
    }
  }, [exportedIdentities]);

  return (
    <Page title="Dashboard">
      <DataGrid
        rows={
          // @ts-ignore
          identities?.results || []
        }
        loading={isLoadingIdentities}
        columns={columns}
        pageSize={pageSize}
        rowCount={
          identities
            ? // @ts-ignore
              identities.count
            : 0
        }
        rowsPerPageOptions={[10, 20, 30]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        density="comfortable"
        editMode="row"
        // autoHeight
        disableSelectionOnClick
        onRowDoubleClick={({ row }) => {
          if (row.get("isLocked")) {
          } else {
            navigate(`${DashboardRoutes.DETAILS}/${row.id}`);
          }
        }}
        paginationMode="server"
        filterMode="server"
        onFilterModelChange={(model, details) => {
          const { items } = model;
          const { reason } = details;

          if (reason !== undefined) {
            setFilters(items);
          }
        }}
        onPageChange={(newPage) => setPage(newPage)}
        components={{
          Footer,
          NoRowsOverlay,
        }}
        componentsProps={{
          footer: {
            disableExportButton:
              (identities &&
                // @ts-ignore
                identities.count === 0) ||
              isLoadingIdentities ||
              isExportingIdentities,
            loadingServices: isLoadingIdentities,
            exportFn: exportIdentities,
          },
        }}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
      <UnlockIdentityFailedDialog
        open={isUnlockIdentityFailedDialogShown}
        onClose={() => setIsUnlockIdentityFailedDialogShown(false)}
      />
    </Page>
  );
};

export default DashboardPage;
