import { useAsync, useAsyncCallback } from "react-async-hook";
import Parse from "parse";
import React from "react";

export const useUnlockIdentity = ({
  identityId,
  onUnlock,
  onError,
}: {
  identityId: string;
  onUnlock?: () => void;
  onError?: () => void;
}) => {
  const { loading, result, execute, error } = useAsyncCallback(
    async () =>
      await Parse.Cloud.run("unlockIdentity", {
        identityId: identityId,
      }),
    {
      onError: onError,
      onSuccess: onUnlock,
    }
  );
  return { isUnlocking: loading, result, execute, error };
};
