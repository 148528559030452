import {
  Box,
  ButtonBase,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { IProductData } from "../types/IProductData";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

const SubscriptionCard: React.FC<{
  product: IProductData;
  isSelected: boolean;
  isBuying: boolean;
  onBuy: () => void;
  onClick: () => void;
}> = ({ onClick, onBuy, isSelected, isBuying, product }) => {
  const theme = useTheme();
  const { t } = useTranslation("buyPage");

  const planTextColor = "black";

  return (
    <ButtonBase
      disableRipple
      onClick={(_) => onClick()}
      sx={{
        padding: 3,
        maxWidth: "21rem",
        boxShadow: "0px 0px 30px rgba(193, 226, 255, 1)",
        borderRadius: "10px",
        overflow: "hidden",
        border:
          "2px solid " +
          (isSelected ? theme.palette.primary.main : "rgba(234, 244, 253, 1)"),
        height: "512px",
        "&:hover": {
          border: "2px solid " + theme.palette.primary.main,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          //background:
          //"radial-gradient(38.09% 61.29% at 6.32% 6.02%, rgba(32, 138, 236, 0.2) 0%,rgba(17, 67, 112, 0.044) 100%),radial-gradient(37.65% 38.94% at 94.56% 102.28%,rgba(38, 61, 90, 0.6) 0%,rgba(16, 32, 58, 0.6) 100%),#0054BB",
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <Typography
            variant="h3"
            color={planTextColor}
            fontWeight="bold"
            mb={0.5}
          >
            {product.name}
          </Typography>
          <Typography variant="caption" color={planTextColor}>
            Ideal to launch an MVP using our serverless architecture
          </Typography>
          <Divider color="gray" sx={{ width: "100%", height: "2px", my: 4 }} />
          <Typography
            variant="h3"
            color={planTextColor}
            fontWeight="bold"
            mb={0.5}
          >
            ${product.price.value}
          </Typography>
          <Typography variant="caption" color={planTextColor}>
            {t("subscriptionCard.billedMonthly")}
          </Typography>
          <Typography variant="caption" color={planTextColor}>
            {t("subscriptionCard.save", { save: "20%" })}
          </Typography>
          <Divider color="gray" sx={{ width: "100%", height: "2px", my: 4 }} />
          <Typography variant="caption" color={planTextColor} fontWeight="bold">
            {product.metadata.identities}{" "}
            <Typography variant="caption" color={planTextColor}>
              {t("identities")}
            </Typography>
          </Typography>
          <Box position="absolute" bottom={16}>
            <LoadingButton
              loading={isSelected && isBuying}
              onClick={() => {
                onClick();
                onBuy();
              }}
              variant={isSelected ? "contained" : "outlined"}
              sx={{
                textTransform: "none",
              }}
            >
              {t("buyNow")}
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </ButtonBase>
  );
};

export default SubscriptionCard;
