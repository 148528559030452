import React from "react";

const useTimer = (seconds: number, callback: () => void) => {
  const [timeLeft, setTimeLeft] = React.useState<number>();
  React.useEffect(() => {
    if (timeLeft === 0) {
      callback();
      return;
    }

    if (!timeLeft) {
      return;
    }

    const id = setInterval(() => {
      setTimeLeft((v) => v! - 1);
    }, 1000);
    return () => clearInterval(id);
  }, [callback, timeLeft]);

  React.useEffect(() => setTimeLeft(seconds), [seconds]);

  return timeLeft;
};

export default useTimer;
