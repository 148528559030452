import { Backdrop, CircularProgress } from "@mui/material";
import { SnackbarProvider } from "notistack";
import Parse from "parse";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DashboardNavbar from "./components/DashboardNavbar";

import "./i18n";
import "./index.css";
import DashboardLayout from "./layout/DashboardLayout";
import DashboardPage from "./pages/DashboardPage";
import DetailsPage from "./pages/DetailsPage";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import ThemeConfig from "./theme/ThemeConfig";
import SettingsPage from "./pages/SettingsPage";
import BuyPage from "./pages/BuyPage";
import YourSubscriptionPage from "./pages/YourSubscriptionPage";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCanceled from "./pages/PaymentCanceled";

Parse.initialize(
  process.env.REACT_APP_PARSE_APP_ID!,
  process.env.REACT_APP_PARSE_JAVASCRIPT_KEY!
);

Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL!;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage"));

export enum DashboardRoutes {
  HOME = "/",
  SIGNUP = "/signup",
  DASHBOARD = "/dashboard",
  BUY = "/buy",
  DETAILS = "/details",
  SETTINGS = "/settings",
  YOUR_SUBSCRIPTION = "/your-subscription",
  PAYMENT_SUCCESS = "/payment-success",
  PAYMENT_CANCELED = "/payment-canceled",
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeConfig>
        <HelmetProvider>
          <Provider store={store}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              preventDuplicate
            >
              <React.Suspense
                fallback={
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open
                  >
                    <CircularProgress />
                  </Backdrop>
                }
              >
                <DashboardNavbar />

                <Routes>
                  <Route element={<DashboardLayout />}>
                    <Route
                      path={DashboardRoutes.HOME}
                      element={<LoginPage />}
                    />

                    <Route
                      path={DashboardRoutes.SIGNUP}
                      element={<RegisterPage />}
                    />

                    <Route
                      path={DashboardRoutes.DASHBOARD}
                      element={<DashboardPage />}
                    />

                    <Route path={DashboardRoutes.BUY} element={<BuyPage />} />

                    <Route
                      path={DashboardRoutes.YOUR_SUBSCRIPTION}
                      element={<YourSubscriptionPage />}
                    />

                    <Route
                      path={DashboardRoutes.SETTINGS}
                      element={<SettingsPage />}
                    />

                    <Route
                      path={`${DashboardRoutes.DETAILS}/:identityId`}
                      element={<DetailsPage />}
                    />

                    <Route
                      path={DashboardRoutes.PAYMENT_SUCCESS}
                      element={<PaymentSuccess />}
                    />

                    <Route
                      path={DashboardRoutes.PAYMENT_CANCELED}
                      element={<PaymentCanceled />}
                    />
                  </Route>
                  <Route
                    path="/"
                    element={<Navigate to={DashboardRoutes.HOME} replace />}
                  />

                  <Route
                    path="*"
                    element={<Navigate to={DashboardRoutes.HOME} replace />}
                  />
                </Routes>
              </React.Suspense>
            </SnackbarProvider>
          </Provider>
        </HelmetProvider>
      </ThemeConfig>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
