import { useAsync } from "react-async-hook";
import Parse from "parse";
import { IProductData } from "../types/IProductData";

export const useUserActiveSubscription = () => {
  const { loading, result, execute, error } = useAsync<
    IProductData | undefined
  >(async () => await Parse.Cloud.run("getUserActiveSubscription"), []);
  return { loading, result, refresh: execute, error };
};
