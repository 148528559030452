import Parse from "parse";
import { useAsync } from "react-use";
import { IProductData } from "../types/IProductData";

const useProducts = () => {
  const a = useAsync(async () => {
    return await Parse.Cloud.run<() => Promise<IProductData[]>>(
      "fetchProducts"
    );
  });
  console.log(a);
  return { products: a.value, isLoading: a.loading, error: a.error };
};

export default useProducts;
