import React from "react";
// material

import { useTranslation } from "react-i18next";
import Page from "../components/Page";




const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("settings")} hidePageTitle={false} showBackButton>
      Settings
    </Page>
  );
};

export default SettingsPage;
