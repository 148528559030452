import Parse from "parse";
import React from "react";
// material

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// layouts
// import AuthLayout from '../../layouts/AuthLayout';
// components
import { useAsync } from "react-async-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardRoutes } from "..";
import images from "../assets/images";
import Page from "../components/Page";
import useQuery from "../hooks/useQuery";
import useTimer from "../hooks/useTimer";
import { IProductData } from "../types/IProductData";
// ----------------------------------------------------------------------

const TIMEOUT = 10;

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.primary.main,
  height: "100%",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

const CardContentStyle = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  padding: theme.spacing(4),
}));
// ----------------------------------------------------------------------

const PaymentSuccess: React.FC = () => {
  const { t } = useTranslation("paymentSuccessPage");
  const query = useQuery();
  const navigate = useNavigate();

  const timeLeft = useTimer(TIMEOUT, () => {
    navigate(DashboardRoutes.DASHBOARD, { replace: true });
  });

  const { loading, result } = useAsync<IProductData>(
    async () =>
      await Parse.Cloud.run("fetchSuccessPaymentData", {
        sessionId: query.get("session_id"),
      }),
    [query]
  );

  return (
    <RootStyle title={t("title")}>
      <ContentStyle>
        {loading && <CircularProgress sx={{ color: "white" }} />}
        <Slide in={!loading && !!result}>
          <Card>
            <CardContentStyle>
              {!loading && result && (
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box component="img" src={images.CheckedIcon} width={48} />
                  <Box height={16} />
                  <Typography variant="h3" color="green" textAlign="center">
                    {t("title")}
                  </Typography>
                  <Box height={64} />
                  <Stack direction="column" width="100%" spacing={2}>
                    <EntryInfo title={t("name")} value={result.name} />
                    <EntryInfo
                      title={t("identities")}
                      value={result.metadata.identities.toString()}
                      isRecurring={result.type === "recurring"}
                    />

                    <Box />
                    <Divider />
                    <Box />

                    <EntryInfo
                      title={t("amountPaid")}
                      value={result.price.value.toLocaleString(undefined, {
                        style: "currency",
                        currency: result.price.currency,
                      })}
                      isRecurring={result.type === "recurring"}
                      boldTitle
                    />
                    <Box height={32} />

                    <Typography textAlign="center">
                      {t("redirect", { seconds: timeLeft })}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  </Stack>
                </Stack>
              )}
            </CardContentStyle>
          </Card>
        </Slide>
      </ContentStyle>
    </RootStyle>
  );
};

export default PaymentSuccess;

const EntryInfo: React.FC<{
  title: string;
  value: string;
  isRecurring?: boolean;
  boldTitle?: boolean;
}> = ({ title, value, isRecurring = false, boldTitle }) => {
  const { t } = useTranslation("paymentSuccessPage");

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography {...(boldTitle ? { fontWeight: "bold" } : {})}>
        {title}
      </Typography>
      <Typography fontWeight="bold">
        {value}
        {isRecurring && (
          <Typography variant="caption" display="inline">
            {t("valuePerMonth")}
          </Typography>
        )}
      </Typography>
    </Stack>
  );
};
