import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CloseIcon from "@mui/icons-material/Close";
import Parse from "parse";
import React from "react";

import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAsync } from "react-async-hook";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

// https://identikyc.com/onboarding?request=asb0zKQbpa&face-recognition&spid

const LoginViaQrDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(["loginViaQrDialog"]);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = React.useCallback(() => {
    // @ts-ignore
    onClose?.();
  }, [onClose]);

  const { loading, result: request } = useAsync(async () => {
    const user = Parse.User.current();

    if (user === undefined) {
      return;
    }

    return await Parse.Cloud.run("getOrCreateLoginRequest");
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullScreen={fullScreen}
      {...props}
      onClose={handleClose}
    >
      <Tooltip
        title={t("closeDialog", {
          ns: "common",
        })}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Box height={64} />
      <DialogContent dividers>
        <Stack spacing={4} alignItems="center" mt={3}>
          <Stack spacing={4} alignItems="center">
            <Stack spacing={1} alignItems="center">
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                  width: 53,
                  height: 53,
                }}
              >
                <AccountBoxIcon
                  sx={{
                    fontSize: "31px",
                  }}
                />
              </Avatar>

              <Typography variant="h4">{t("title")}</Typography>
              <Typography variant="body1" textAlign="center">
                {t("subtitle")}
              </Typography>
            </Stack>
            {loading && <CircularProgress />}
            {!loading && request && (
              <Box
                sx={{
                  width: 256,
                  height: 256,
                }}
              >
                <QRCode
                  size={128}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={request.deepLink}
                  viewBox={`0 0 128 128`}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default LoginViaQrDialog;
