import Parse from "parse";
import React from "react";
import { Link } from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
// material
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../components/Logo";

import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import { MHidden } from "../@material_extend";
import AccountPopover from "../components/AccountPopover";

import LanguagePopover from "./LanguagePopover";

// ----------------------------------------------------------------------

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  minHeight: theme.appBar.md,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  width: "60px",
  maxWidth: "70px",
  display: "inline-block",
  margin: "0",
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

const links = [
  {
    to: "https://www.identikyc.com/",
    icon: <DownloadIcon color="primary" />,
    text: "Download",
  },
  /*{
    to: "/about",
    icon: <InfoIcon color="primary" />,
    text: "About",
  },*/
];

const DashboardNavbar: React.FC<{
  onOpenSidebar?: React.MouseEventHandler<HTMLButtonElement>;
}> = () => {
  const currentUser = Parse.User.current();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      {links.map(({ to, icon, text }, index) => (
        <ListItem disablePadding key={`${to}-${index}`}>
          <ListItemButton component={Link} to={to} onClick={handleDrawerToggle}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {!currentUser && (
        <Drawer
          container={document.body}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 200,
            },
          }}
        >
          {drawer}
        </Drawer>
      )}

      <AppBar>
        <Container maxWidth="xl">
          <StyledToolbar disableGutters>
            <Stack
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              columnGap={2}
            >
              {!currentUser && (
                <MHidden width="mdUp">
                  <IconButton
                    onClick={handleDrawerToggle}
                    sx={{ color: "white", marginTop: "0px" }}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </MHidden>
              )}

              <MHidden width="smDown">
                <StyledLink to="/">
                  <Logo maxWidth={"160px"} />
                </StyledLink>
              </MHidden>
            </Stack>

            <Box id="navbar-page-title" />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              {!currentUser && (
                <MHidden width="mdDown">
                  <Stack spacing={1} direction="row" mr={4}>
                    {links.map(({ to, text, icon }, index) => (
                      <Button
                        component={Link}
                        key={`${to}-${index}`}
                        to={to}
                        color="inherit"
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                        }}
                        startIcon={React.cloneElement(icon, {
                          color: "white",
                        })}
                      >
                        {text}
                      </Button>
                    ))}
                  </Stack>
                </MHidden>
              )}

              <LanguagePopover />

              <AccountPopover />
            </Stack>
          </StyledToolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default DashboardNavbar;
