// material
import { Box, BoxProps } from "@mui/material";
import React from "react";
import images from "../assets/images";

const Logo: React.FC<BoxProps> = (props) => {
  return <Box component="img" src={images.LogoWhite} {...props} />;
};

export default Logo;
