import Parse from "parse";
import { useAsync } from "react-async-hook";

export interface IInvoiceData {
  id: string;

  periodStart: number;
  periodEnd: number;

  hostedInvoiceUrl: string;
  invoicePdf: string;
}

export const useInvoices = () => {
  const { loading, result, execute } = useAsync<IInvoiceData[]>(async () => {
    return await Parse.Cloud.run("getCustomerInvoices");
  }, []);
  return { loading, result: result ?? [], refresh: execute };
};
