const NODE_ENV = process.env.NODE_ENV;
const REACT_APP_CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL!;

const cdnPath = `${REACT_APP_CDN_BASE_URL}/assets/images/`;

const getImageUrl = (imagePath: string) => {
  if (NODE_ENV === "production") {
    const tmp = imagePath.split("/").at(-1)!.split(".");

    return cdnPath.concat([tmp[0], tmp[2]].join("."));
  } else {
    return imagePath;
  }
};

export default getImageUrl;
