// import { Icon } from '@iconify/react';
import { LoadingButton } from "@mui/lab";
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// components
//
import LockResetIcon from "@mui/icons-material/LockReset";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SecurityUpdateIcon from "@mui/icons-material/SecurityUpdate";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Parse from "parse";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import { DashboardRoutes } from "..";
import MenuPopover from "../components/MenuPopover";
import CreateSelfCheckInDialog from "./Dialogs/CreateSelfCheckInDialog";
import LoginViaQrDialog from "./Dialogs/LoginViaQrDialog";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ResetPasswordDialog = React.lazy(
  () => import("./Dialogs/ResetPasswordDialog")
);

const DownloadAppDialog = React.lazy(
  () => import("./Dialogs/DownloadAppDialog")
);

const AccountPopover: React.FC = () => {
  const { t } = useTranslation();

  const MENU_OPTIONS: {
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
      muiName: string;
    };
    linkTo: string;
  }[] = [
    {
      label: t("settings"),
      icon: SettingsIcon,
      linkTo: DashboardRoutes.SETTINGS,
    },
    {
      label: t("yourSubscription"),
      icon: AccountBoxIcon,
      linkTo: DashboardRoutes.YOUR_SUBSCRIPTION,
    },
    {
      label: t("buy"),
      icon: AccountBalanceIcon,
      linkTo: DashboardRoutes.BUY,
    },
  ];

  const [
    isResetPasswordDialogTransitionPending,
    startResetPasswordDialogTransition,
  ] = React.useTransition();

  const [
    isDownloadAppDialogTransitionPending,
    startDownloadAppDialogTransition,
  ] = React.useTransition();

  const [
    isSelfCheckInDialogTransitionPending,
    startSelfCheckInDialogTransition,
  ] = React.useTransition();

  const [isLoginViaQrDialogTransitionPending, startLoginViaQrDialogTransition] =
    React.useTransition();

  const user = Parse.User.current();

  const anchorRef = React.useRef(null);

  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoggingOut, setLoggingOut] = React.useState<boolean>(false);

  const [shouldLoadSelfCheckInDialog, setShouldLoadSelfCheckInDialog] =
    useToggle(false);
  const [isSelfCheckInDialogVisibile, toggleSelfCheckInDialogVisibility] =
    useToggle(false);

  const [shouldLoadLoginViaQrDialog, setShouldLoadLoginViaQrDialog] =
    useToggle(false);
  const [isLoginViaQrDialogVisibile, toggleLoginViaQrDialogVisibility] =
    useToggle(false);

  const [shouldLoadResetPasswordDialog, setShouldLoadResetPasswordDialog] =
    useToggle(false);
  const [isResetPasswordDialogVisibile, toggleResetPasswordDialogVisibility] =
    useToggle(false);

  const [shouldLoadDownloadAppDialog, setShouldLoadDownloadAppDialog] =
    useToggle(false);
  const [isDownloadAppDialogVisibile, toggleDownloadAppDialogVisibility] =
    useToggle(false);

  const userInitials = user
    ?.get("email")
    .split("@")[0]
    .split(".")
    .map((str: string) => str[0].toUpperCase())
    .join("");

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      await Parse.User.logOut();
      setOpen(false);
    } finally {
      navigate(DashboardRoutes.HOME, { replace: true });
      setLoggingOut(false);
    }
  };

  const handleSelfCheckInDialogVisibility = () => {
    if (!shouldLoadSelfCheckInDialog) {
      startSelfCheckInDialogTransition(() => {
        setShouldLoadSelfCheckInDialog(true);
        toggleSelfCheckInDialogVisibility(true);
        handleClose();
      });
    } else {
      toggleSelfCheckInDialogVisibility();
      handleClose();
    }
  };

  const handleLoginViaQrDialogVisibility = () => {
    if (!shouldLoadLoginViaQrDialog) {
      startLoginViaQrDialogTransition(() => {
        setShouldLoadLoginViaQrDialog(true);
        toggleLoginViaQrDialogVisibility(true);
        handleClose();
      });
    } else {
      toggleLoginViaQrDialogVisibility();
      handleClose();
    }
  };

  const handleResetPasswordDialogVisibility = () => {
    if (!shouldLoadResetPasswordDialog) {
      startResetPasswordDialogTransition(() => {
        setShouldLoadResetPasswordDialog(true);
        toggleResetPasswordDialogVisibility(true);
        handleClose();
      });
    } else {
      toggleResetPasswordDialogVisibility();
      handleClose();
    }
  };

  const handleAppDownloadDialogVisibility = () => {
    if (!shouldLoadDownloadAppDialog) {
      startDownloadAppDialogTransition(() => {
        setShouldLoadDownloadAppDialog(true);
        toggleDownloadAppDialogVisibility(true);
        handleClose();
      });
    } else {
      toggleDownloadAppDialogVisibility();
      handleClose();
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar src={user?.get("photoURL")} alt={userInitials}>
          {userInitials}
        </Avatar>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.get("email")}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem
          onClick={handleSelfCheckInDialogVisibility}
          sx={{ typography: "body2", py: 1, px: 2 }}
          disabled={isSelfCheckInDialogTransitionPending}
        >
          <Box
            component={AccountBoxIcon}
            sx={{
              mr: 1,
              width: 24,
              height: 24,
            }}
          />
          {t("selfCheckIn")}
        </MenuItem>
        <MenuItem
          onClick={handleLoginViaQrDialogVisibility}
          sx={{ typography: "body2", py: 1, px: 2 }}
          disabled={isLoginViaQrDialogTransitionPending}
        >
          <Box
            component={LoginIcon}
            sx={{
              mr: 1,
              width: 24,
              height: 24,
            }}
          />
          {t("loginViaQr")}
        </MenuItem>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2 }}
          >
            <Box
              component={option.icon}
              sx={{
                mr: 1,
                width: 24,
                height: 24,
              }}
            />
            {option.label}
          </MenuItem>
        ))}

        <MenuItem
          onClick={handleResetPasswordDialogVisibility}
          sx={{ typography: "body2", py: 1, px: 2 }}
          disabled={isResetPasswordDialogTransitionPending}
        >
          <Box
            component={LockResetIcon}
            sx={{
              mr: 1,
              width: 24,
              height: 24,
            }}
          />
          {t("forgotPassword")}
        </MenuItem>

        <MenuItem
          onClick={handleAppDownloadDialogVisibility}
          sx={{ typography: "body2", py: 1, px: 2 }}
          disabled={isDownloadAppDialogTransitionPending}
        >
          <Box
            component={SecurityUpdateIcon}
            sx={{
              mr: 1,
              width: 24,
              height: 24,
            }}
          />
          {t("downloadApp")}
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <LoadingButton
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
            endIcon={<LogoutIcon />}
            loading={isLoggingOut}
          >
            Logout
          </LoadingButton>
        </Box>
      </MenuPopover>

      {shouldLoadResetPasswordDialog && (
        <React.Suspense fallback={null}>
          <ResetPasswordDialog
            open={isResetPasswordDialogVisibile}
            onClose={handleResetPasswordDialogVisibility}
          />
        </React.Suspense>
      )}

      {shouldLoadDownloadAppDialog && (
        <React.Suspense fallback={null}>
          <DownloadAppDialog
            open={isDownloadAppDialogVisibile}
            onClose={handleAppDownloadDialogVisibility}
          />
        </React.Suspense>
      )}

      {shouldLoadSelfCheckInDialog && (
        <React.Suspense fallback={null}>
          <CreateSelfCheckInDialog
            open={isSelfCheckInDialogVisibile}
            onClose={handleSelfCheckInDialogVisibility}
          />
        </React.Suspense>
      )}

      {shouldLoadLoginViaQrDialog && (
        <React.Suspense fallback={null}>
          <LoginViaQrDialog
            open={isLoginViaQrDialogVisibile}
            onClose={handleLoginViaQrDialogVisibility}
          />
        </React.Suspense>
      )}
    </>
  );
};

export default AccountPopover;
