import Parse from "parse";
import React from "react";
import { useAsync } from "react-async-hook";
// material
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Image from "mui-image";

import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Page from "../components/Page";
import PageLoader from "../components/PageLoader";
import useDownloadPdf from "../hooks/useDownloadPdf";

interface ExpandMoreButtonProps extends ButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreButtonProps) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  svg: {
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const DetailsPage: React.FC = () => {
  const { identityId } = useParams();

  const { t } = useTranslation(["common", "detailsPage"]);

  const { loading: isLoadingIdentityDetails, result: identityDetails } =
    useAsync(async () => {
      if (identityId) {
        const query = new Parse.Query("Identity");

        query.equalTo("objectId", identityId);

        query.include("liveness");

        return await query.first();
      }
    }, [identityId]);

  const { isDownloadingPdf, downloadPdf } = useDownloadPdf({ identityDetails });

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded((wasExpanded) => !wasExpanded);
  };

  if (isLoadingIdentityDetails) {
    return <PageLoader />;
  }

  return (
    <Page title={t("details")} hidePageTitle={false} showBackButton>
      <Stack
        justifyContent="space-around"
        alignItems="center"
        minHeight="100%"
        spacing={4}
      >
        <Card
          sx={(theme) => ({
            width: "100vw",
            [theme.breakpoints.up("md")]: {
              marginTop: 8,
              width: "70vw",
            },
            overflow: "auto",
          })}
          elevation={5}
        >
          <CardContent
            sx={{
              position: "relative",
            }}
          >
            <Stack flexDirection="column" position="absolute" right="20px">
              <Typography variant="caption" color="gray">
                {t("authDate")}
              </Typography>

              <Typography fontWeight="bold">
                {new Intl.DateTimeFormat("it-IT").format(
                  identityDetails?.get("createdAt")
                )}
              </Typography>
            </Stack>

            <Stack flexDirection="column" spacing={3}>
              <Stack
                gap={3}
                sx={(theme) => ({
                  flexDirection: "column",
                  [theme.breakpoints.up("sm")]: {
                    flexDirection: "row",
                  },
                })}
              >
                <Image
                  src={identityDetails?.get("photo").url()}
                  duration={0}
                  width="200px"
                  height="200px"
                />

                <Stack flexDirection="column" gap={3}>
                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("firstName")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("firstName") ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("lastName")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("lastName") ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Divider />

              <Stack flexDirection="row" justifyContent="space-between">
                <Stack spacing={1.25}>
                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("documentNumber")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("documentNumber") ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("gender")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {t(identityDetails?.get("gender")) ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("issueDate")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("dateOfIssue")
                        ? new Intl.DateTimeFormat("it-IT").format(
                            identityDetails?.get("dateOfIssue")
                          )
                        : t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("personalNumber")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("personalNumber") ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1.25}>
                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("birthDate")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("dateOfBirth")
                        ? new Intl.DateTimeFormat("it-IT").format(
                            identityDetails?.get("dateOfBirth")
                          )
                        : t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("nationality")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("nationality") ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("expiryDate")}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("dateOfExpiry")
                        ? new Intl.DateTimeFormat("it-IT").format(
                            identityDetails?.get("dateOfExpiry")
                          )
                        : t("notAvailableAcronym")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>

          <CardActions>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              endIcon={<ExpandMoreIcon />}
            >
              {t(expanded ? "lessDetails" : "moreDetails")}
            </ExpandMore>
          </CardActions>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Container
              sx={{
                paddingBottom: 4,
              }}
            >
              <Stack
                direction={{ md: "row", sm: "column" }}
                justifyContent="space-between"
                spacing={1}
              >
                {identityDetails?.get("mrzImage") && (
                  <Image
                    src={identityDetails?.get("mrzImage")?.url() || ""}
                    duration={0}
                    fit="cover"
                    width="60%"
                    style={{
                      maxHeight: "200px",
                    }}
                  />
                )}
                {identityDetails?.get("liveness")?.get("selfie") && (
                  <Image
                    src={
                      identityDetails?.get("liveness")?.get("selfie")?.url() ||
                      ""
                    }
                    duration={0}
                    fit="contain"
                    width="40%"
                    style={{
                      maxHeight: "200px",
                    }}
                  />
                )}
                <Stack spacing={1}>
                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("gender")}
                    </Typography>

                    <Typography fontWeight="bold" textTransform="capitalize">
                      {t(identityDetails?.get("liveness")?.get("gender")) ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("somaticTraits", {
                        ns: "detailsPage",
                      })}
                    </Typography>

                    <Typography fontWeight="bold" textTransform="capitalize">
                      {identityDetails?.get("liveness")?.get("somaticTraits") ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("genderPrediction", {
                        ns: "detailsPage",
                      })}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails
                        ?.get("liveness")
                        ?.get("genderPrediction")
                        .toFixed(2) || t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("presumedAge", {
                        ns: "detailsPage",
                      })}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails?.get("liveness")?.get("age") ||
                        t("notAvailableAcronym")}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1}>
                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("somaticTraitsPrediction", {
                        ns: "detailsPage",
                      })}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails
                        ?.get("liveness")
                        ?.get("somaticTraitsPrediction")
                        .toFixed(2) || t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("matchPrediction", {
                        ns: "detailsPage",
                      })}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails
                        ?.get("liveness")
                        ?.get("matchPrediction")
                        .toFixed(2) || t("notAvailableAcronym")}
                    </Typography>
                  </Stack>

                  <Stack flexDirection="column">
                    <Typography variant="caption" color="gray">
                      {t("fakenessPrediction", {
                        ns: "detailsPage",
                      })}
                    </Typography>

                    <Typography fontWeight="bold">
                      {identityDetails
                        ?.get("liveness")
                        ?.get("fakenessPrediction")
                        .toFixed(2) || t("notAvailableAcronym")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </Collapse>
        </Card>

        <LoadingButton
          startIcon={<DownloadIcon />}
          variant="contained"
          size="large"
          sx={(theme) => ({
            marginBottom: "40px !important",
            width: "450px",
            [theme.breakpoints.down("sm")]: {
              width: "95vw",
            },
          })}
          loading={isDownloadingPdf}
          onClick={() => downloadPdf()}
        >
          PDF
        </LoadingButton>
      </Stack>
    </Page>
  );
};

export default DetailsPage;
