import React from "react";
import { Outlet } from "react-router-dom";

const DashboardLayout: React.FC<{
  children?: React.ReactNode;
}> = () => {
  return <Outlet />;
};

export default DashboardLayout;
