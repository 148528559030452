import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import Parse from "parse";
import React from "react";
import { useAsyncCallback } from "react-async-hook";

const useDeleteIdentity = ({
  identityDetails,
  onDelete,
}: {
  identityDetails?: Parse.Object<Parse.Attributes>;
  onDelete: () => void;
}) => {
  const {
    loading: isDeletingIdentity,
    error: errorDeletingIdentity,
    result: deleteResult,
    execute: deleteIdentity,
  } = useAsyncCallback(async () => {
    if (identityDetails) {
      return await identityDetails.destroy();
    }
  });

  React.useEffect(() => {
    if (identityDetails && deleteResult) {
      onDelete();
    }
  }, [deleteResult, identityDetails, onDelete]);

  return { isDeletingIdentity, errorDeletingIdentity, deleteIdentity };
};

export default useDeleteIdentity;
