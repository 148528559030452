import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CloseIcon from "@mui/icons-material/Close";
import Parse from "parse";
import React from "react";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAsync } from "react-async-hook";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

// https://identikyc.com/onboarding?request=asb0zKQbpa&face-recognition&spid

const CreateSelfCheckInDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(["createSelfCheckInDialog"]);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [face, setFace] = React.useState(false);
  const [spid, setSPID] = React.useState(false);

  const [, setIsSaving] = React.useState(false);

  const handleClose = React.useCallback(() => {
    // @ts-ignore
    onClose?.();
  }, [onClose]);

  const {
    loading: isLoadingOnboardingRequest,
    result: onboardingRequest,
    execute: refreshOnboardingRequest,
  } = useAsync(async () => {
    const user = Parse.User.current();
    if (user === undefined) {
      return;
    }
    const query = new Parse.Query("OnboardingRequest");

    query.equalTo("owner", user);

    return await query.first();
  }, []);

  React.useEffect(() => {
    if (onboardingRequest === undefined) {
      return;
    }
    setFace(onboardingRequest.get("faceRecognition"));
    setSPID(onboardingRequest.get("spid"));
  }, [onboardingRequest]);

  const qrValue = `https://identikyc.com/onboarding?request=${onboardingRequest?.id}`;

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullScreen={fullScreen}
      {...props}
      onClose={handleClose}
    >
      <Tooltip
        title={t("closeDialog", {
          ns: "common",
        })}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Box height={64} />
      <DialogContent dividers>
        <Stack spacing={4} alignItems="center" mt={3}>
          <Stack spacing={4} alignItems="center">
            <Stack spacing={1} alignItems="center">
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                  width: 53,
                  height: 53,
                }}
              >
                <AccountBoxIcon
                  sx={{
                    fontSize: "31px",
                  }}
                />
              </Avatar>

              <Typography variant="h4">{t("title")}</Typography>
            </Stack>
            {isLoadingOnboardingRequest && <CircularProgress />}
            {onboardingRequest && (
              <>
                <Stack spacing={2} alignItems="center">
                  <Typography textAlign="center">{t("shareQr")}</Typography>
                  <Box
                    sx={{
                      width: 256,
                      height: 256,
                      marginTop: 0,
                    }}
                  >
                    <QRCode
                      size={128}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={qrValue}
                      viewBox={`0 0 128 128`}
                    />
                  </Box>
                  <Typography textAlign="center" marginTop="0">
                    {t("share")}
                    <br></br>
                    <a href={qrValue}>{qrValue}</a>
                  </Typography>
                </Stack>
                <Divider sx={{ width: "100%", height: "2px" }} />
                <Stack spacing={1} alignItems="center">
                  <Typography fontWeight="bold" textAlign="center">
                    {t('changes')}
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={face}
                          onChange={(event) => setFace(event.target.checked)}
                        />
                      }
                      label={t("faceRecognition")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={spid}
                          onChange={(event) => setSPID(event.target.checked)}
                        />
                      }
                      label={t("spid")}
                    />
                  </FormGroup>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      if (onboardingRequest === undefined) {
                        return;
                      }

                      onboardingRequest.set("faceRecognition", face);
                      onboardingRequest.set("spid", spid);

                      try {
                        setIsSaving(true);
                        await onboardingRequest.save();
                      } catch (e) {
                        console.log(e);
                      } finally {
                        setIsSaving(false);
                      }

                      refreshOnboardingRequest();
                    }}
                  >
                    {t("save")}
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreateSelfCheckInDialog;
