import DownloadIcon from "@mui/icons-material/SystemUpdateAlt";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Stack } from "@mui/material";
import { GridFooter } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

interface IFooterProperties {
  isExportingData?: boolean;
  exportFn?: LoadingButtonProps["onClick"];
  disableExportButton?: boolean;
  showExportButton?: boolean;
}

export const Footer: React.FC<IFooterProperties> = ({
  disableExportButton,
  isExportingData,
  exportFn,
  showExportButton = true,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      px={4}
      borderTop="1px solid rgb(241, 243, 244)"
    >
      {showExportButton && (
        <LoadingButton
          loading={isExportingData}
          onClick={exportFn}
          disabled={disableExportButton}
          startIcon={<DownloadIcon />}
          sx={{
            textTransform: "uppercase",
          }}
        >
          {t("export")}
        </LoadingButton>
      )}

      {/* <GridToolbarExport /> */}

      <GridFooter
        style={{
          marginLeft: "auto",
          borderTop: "none",
        }}
      />
    </Stack>
  );
};
