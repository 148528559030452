import React from "react";
import { useNavigate } from "react-router-dom";
// material
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import usePortal from "react-useportal";

interface IPageTitleProps {
  children: React.ReactNode;
  showBackButton: boolean;
  hidePageTitle: boolean;
}

const Title: React.FC<IPageTitleProps> = ({
  children,
  showBackButton,
  hidePageTitle,
}) => {
  const { Portal } = usePortal({
    // @ts-ignore
    bindTo: document && document.getElementById("navbar-page-title"),
  });
  const navigate = useNavigate();

  return (
    <Portal>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => ({
          position: "absolute",
          left: "50%",
          top: "12.5%",
          transform: "translateX(-50%)",
          [theme.breakpoints.down("lg")]: {
            left: "42.5%",
          },
        })}
        columnGap={1}
      >
        {showBackButton && (
          <Tooltip enterDelay={1000} title="Torna indietro">
            <IconButton
              size="large"
              onClick={() => navigate(-1)}
              sx={{ color: "white" }}
            >
              <KeyboardBackspaceIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}

        {!hidePageTitle && (
          <Typography
            variant="h4"
            color="white"
            sx={{
              marginTop: "0 !important",
            }}
          >
            {children}
          </Typography>
        )}
      </Stack>
    </Portal>
  );
};

export default Title;
