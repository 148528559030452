import React from "react";
// material
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import Parse from "parse";
import { useAsyncCallback } from "react-async-hook";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import SubscriptionCard from "../components/SubscriptionCard";
import useProducts from "../hooks/useProducts";
import { IProductData } from "../types/IProductData";
import { LoadingButton } from "@mui/lab";

const freeTierProducts = ["prod_O7NxewU9IOh2EF", "prod_ONnKlyvyp9FSo6"];

type TMenuType = "subscriptions" | "one-time";

const BuyPage: React.FC = () => {
  const { t } = useTranslation("buyPage");

  const [type, setType] = React.useState<TMenuType>("subscriptions");

  const { products } = useProducts();

  return (
    <Page title="Subscription">
      <Stack flexDirection="column" alignItems="center" mt={4} pb={4}>
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={1}
          pt={6}
          pb={6}
        >
          <Typography variant="h1" fontWeight="bold" textAlign="center">
            {t("title")}
          </Typography>
          <Typography variant="h5" textAlign="center" whiteSpace="pre-line">
            {t("subtitle")}
          </Typography>
        </Stack>

        {/*<Box
          padding="2.5rem"
          border="1px solid rgb(193 226 255/.5)"
          mx={4}
          width={{
            md: "60rem",
          }}
        >
          <Stack
            flexDirection={{
              md: "row",
            }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h2" minWidth="10rem">
              {t("freePlan.title")}
            </Typography>
            {products && (
              <Typography fontSize="0.875rem" textAlign="center">
                {t("freePlan.subtitle", {
                  identities: products.find((e) => e.id === freeTierProductId)!
                    .metadata.identities,
                })}
              </Typography>
            )}
            <Box width={8} />
          </Stack>
        </Box>*/}
        <Box my={4} />
        <ToggleButtonGroup
          color="primary"
          value={type}
          exclusive
          onChange={(_, newValue: TMenuType) => {
            setType(newValue);
          }}
          aria-label="Type"
        >
          <ToggleButton value="subscriptions" sx={{ flex: 1 }}>
            Subscriptions
          </ToggleButton>
          <ToggleButton value="packages" sx={{ flex: 1 }}>
            One-Time
          </ToggleButton>
        </ToggleButtonGroup>
        <Box my={4} />
        {products ? (
          type === "subscriptions" ? (
            <SubscriptionType products={products} />
          ) : (
            <OneTimeType products={products} />
          )
        ) : (
          <CircularProgress />
        )}
      </Stack>
    </Page>
  );
};

export default BuyPage;

const SubscriptionType: React.FC<{
  products: IProductData[];
}> = ({ products }) => {
  const [selected, setSelected] = React.useState(1);
  const { loading: isLoadingPaymentSession, execute: executePayment } =
    useAsyncCallback(
      async (subscriptionModel: IProductData) =>
        await Parse.Cloud.run("createCheckoutSession", {
          priceId: subscriptionModel.price.id,
        }),
      {
        onSuccess: (e, _) => {
          window.location.replace(e);
        },
      }
    );
  return (
    <Stack direction="column">
      <Stack
        direction={{
          md: "row",
        }}
        spacing={{
          sm: 4,
          md: 4,
        }}
      >
        {products &&
          products
            .filter(
              (v) => v.type === "recurring" && !freeTierProducts.includes(v.id)
            )
            .sort((a, b) => a.price.value - b.price.value)
            .map((product, index) => {
              return (
                <SubscriptionCard
                  key={product.id}
                  product={product}
                  isSelected={selected === index}
                  isBuying={isLoadingPaymentSession}
                  onClick={() => setSelected(index)}
                  onBuy={() => executePayment(product)}
                />
              );
            })}
      </Stack>
    </Stack>
  );
};

const OneTimeType: React.FC<{
  products: IProductData[];
}> = ({ products }) => {
  const { t } = useTranslation("buyPage");
  const theme = useTheme();
  const [selected, setSelected] = React.useState(0);

  const { loading: isLoadingPaymentSession, execute: executePayment } =
    useAsyncCallback(
      async (subscriptionModel: IProductData) =>
        await Parse.Cloud.run("createCheckoutSession", {
          priceId: subscriptionModel.price.id,
        }),
      {
        onSuccess: (e, _) => {
          window.location.replace(e);
        },
      }
    );

  const oneTimeProducts = products
    .filter((v) => v.type === "one_time" && !freeTierProducts.includes(v.id))
    .sort((a, b) => a.price.value - b.price.value);

  const marks = oneTimeProducts.map((v, i) => ({
    value: (100 / (oneTimeProducts.length - 1)) * i,
    label: `${v.metadata.identities} Identities`,
  }));

  const selectedProduct = oneTimeProducts[selected];
  return (
    <Stack direction="column">
      <Stack alignItems="center">
        <Box width="100%" paddingX={4}>
          <Slider
            step={null}
            marks={marks}
            defaultValue={marks[selected].value}
            valueLabelDisplay="off"
            track={false}
            onChange={(_, v) => {
              setSelected(marks.findIndex((m) => m.value === v));
            }}
          />
        </Box>
        <Box height={4} />
        <Card
          sx={{
            minWidth: { md: "50vw" },
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "8px",
            padding: theme.spacing(4, 4, 4),
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h2" color="primary" textAlign="center">
              {selectedProduct.name}
            </Typography>
            <Divider sx={{ width: "100%", height: "2px", my: 4 }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h6" color={theme.palette.primary.light}>
                {t("costPerVerification")}
              </Typography>
              <Typography variant="h6" color={theme.palette.primary.dark}>
                {(
                  selectedProduct.metadata.identities /
                  selectedProduct.price.value
                ).toLocaleString(undefined, {
                  style: "currency",
                  currency: selectedProduct.price.currency,
                })}
              </Typography>
            </Stack>
            <Box height={8} />
            <Typography color={theme.palette.primary.light} variant="h6">
              {t("nOfVerifications", {
                verifications: selectedProduct.metadata.identities,
              })}
            </Typography>
            <Divider sx={{ width: "100%", height: "2px", my: 4 }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography
                variant="h5"
                color={theme.palette.primary.light}
                flex={1}
              >
                {t("totalPrice")}
              </Typography>
              <Typography
                variant="h5"
                color={theme.palette.primary.dark}
                flex={1}
              >
                {selectedProduct.price.value.toLocaleString(undefined, {
                  style: "currency",
                  currency: selectedProduct.price.currency,
                })}
              </Typography>
            </Stack>
            <Divider sx={{ width: "100%", height: "2px", my: 4 }} />
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <LoadingButton
              loading={isLoadingPaymentSession}
              onClick={() => {
                executePayment(selectedProduct);
              }}
              sx={{
                textTransform: "none",
              }}
              size="large"
              variant="contained"
            >
              {t("buyNow")}
            </LoadingButton>
          </CardActions>
        </Card>
      </Stack>
    </Stack>
  );
};
