import getImageUrl from "../../utils/getImageUrl";
import AppStoreDownload from "./app-store-download.svg";
import IconContained from "./icon-contained.png";
import Icon from "./icon.png";
import LogoBlack from "./logo-black.png";
import LogoWhite from "./logo-white.png";
import Checked from "./checked.png";
import Cancel from "./cancel.png";

const images = {
  IconContained: getImageUrl(IconContained),
  Icon: getImageUrl(Icon),
  LogoBlack: getImageUrl(LogoBlack),
  LogoWhite: getImageUrl(LogoWhite),
  AppStoreDownload: getImageUrl(AppStoreDownload),
  CheckedIcon: getImageUrl(Checked),
  CancelIcon: getImageUrl(Cancel),
};

export default images;
