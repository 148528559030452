import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import Parse from "parse";
import React from "react";
import { useAsyncCallback } from "react-async-hook";

const useDownloadPdf = ({
  identityDetails,
}: {
  identityDetails?: Parse.Object<Parse.Attributes>;
}) => {
  const {
    loading: isDownloadingPdf,
    error: errorDownloadingPdf,
    result: pdf,
    execute: downloadPdf,
  } = useAsyncCallback(async () => {
    if (identityDetails) {
      return await Parse.Cloud.run("generatePdf", {
        oid: identityDetails.id,
      });
    }
  });

  React.useEffect(() => {
    if (identityDetails && pdf) {
      const firstName = identityDetails.get("firstName");
      const lastName = identityDetails.get("lastName");

      const buffer = Buffer.from(pdf, "base64");

      saveAs(
        new Blob([buffer], {
          type: "application/pdf",
        }),
        `${firstName} ${lastName}`
      );
    }
  }, [pdf, identityDetails]);

  return { isDownloadingPdf, errorDownloadingPdf, downloadPdf };
};

export default useDownloadPdf;
