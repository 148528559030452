import { Stack, Typography } from "@mui/material";
import { GridOverlay, GridOverlayProps } from "@mui/x-data-grid";
import React from "react";

export const NoRowsOverlay: React.FC<GridOverlayProps> = (properties) => (
  <GridOverlay {...properties}>
    <Stack spacing={6} alignItems="center">
      <img
        src={""}
        alt=""
        style={{
          width: "300px",
        }}
      />

      <Typography variant="subtitle1">Nessun dato trovato</Typography>
    </Stack>
  </GridOverlay>
);
